.coursePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .buttonGroup {
    display: flex;
    width: 100%;
    margin: 1rem 0;
    flex-wrap: wrap;
    justify-content: space-between;
    .buttonSubgroup {
      display: flex;
    }
  }
  .courseDetails {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .courseCode {
      font-size: 2rem;
      margin: 0.25rem 0;
      @media screen and (max-width: 425px) {
        font-size: 1.25rem;
      }
    }
    .courseTitle {
      text-transform: uppercase;
      margin: 0.25rem 0;
      @media screen and (max-width: 425px) {
        font-size: 0.85rem;
      }
    }
  }
  .signInHint {
    text-transform: uppercase;
    font-size: 0.75rem;
    align-self: flex-end;
    margin: 0.5rem;
  }
  .linksList {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    .linkDiv {
      padding: 1rem;
      box-sizing: border-box;
      .linkTitle {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
      .linkPanel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 25rem;
        transition: max-height ease-in-out var(--transition-time);
        overflow: clip;
        width: 100%;
        .signInHint {
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          font-size: 0.75rem;
          margin: 0.5rem;
          color: #cbd5e0;
        }
        .buttonGroup {
          display: flex;
          align-self: flex-end;
          width: auto;
          margin: 0.25rem;
        }
      }
      .hiddenLinkPanel {
        max-height: 0;
      }
    }
  }
}
.addLinkPanel {
  display: flex;
  flex-direction: column;
  .subHeading {
    font-size: 1.25rem;
  }
  .inputGroup {
    margin: 0.75rem 0;
    display: flex;
    flex-direction: column;
    .inputLabel {
      text-transform: uppercase;
      font-size: 0.85rem;
      margin: 0.25rem 0.5rem;
    }
    .input {
      background: #00000000;
      padding: 0.1rem 0.75rem;
      border: 3px solid #e5e5e5;
      border-radius: 1.25rem;
      color: #edf2f7;
    }
  }
  .buttonGroup {
    align-self: flex-end;
    display: flex;
    margin: 0.5rem;
  }
  .linkPreview {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}
