.loadingScreen {
  background-color: #171923;
  color: #edf2f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  transition: max-height 500ms;
  .heading {
    font-size: 3rem;
  }
  .subHeading {
    font-size: 0.85rem;
    text-transform: uppercase;
  }
}
.hide {
  max-height: 0;
  overflow: hidden;
}
