.button {
  margin: 0.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  background-color: #3182ce;
  .buttonIcon,
  .buttonText {
    color: #edf2f7;
    margin: 0.25rem;
    :hover {
      text-decoration: none;
    }
  }
}
.linkContainer {
  width: fit-content;
}
