.homeContainer {
  padding: 0.5rem;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex: 1 1 auto;
  .heading {
    font-size: 2.25rem;
    text-transform: uppercase;
  }
  .description {
    margin: 1rem 0;
    text-align: center;
    font-size: 1rem;
  }
  .buttonGroup {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .coverImage {
    max-width: min(45vw, 15rem);
    margin: 1rem 0;
  }
}
