.coursePanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  .buttonGroup {
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
  }
  .actionButtonGroup {
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0.5rem;
    .buttonCancel,
    .buttonConfirm {
      margin: 0.2rem;
      display: flex;
      align-content: center;
      align-items: center;
      width: fit-content;
      .buttonIcon,
      .buttonText {
        margin: 0.25rem;
      }
    }
  }
}
