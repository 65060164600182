.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  .buttonGroup {
    margin: 0.5rem;
  }
  .headerText {
    margin: 0.5rem;
    font-size: 2.25rem;
    align-self: center;
    @media screen and (max-width: 425px) {
      margin: 0.25rem;
      font-size: 1.5rem;
    }
  }
  .headerSubText {
    margin: 0.25rem;
    font-size: 1.25rem;
    align-self: center;
    width: fit-content;
    @media screen and (max-width: 425px) {
      margin: 0.125rem;
      font-size: 1.25rem;
    }
  }
  .mainPanel {
    display: flex;
    width: 100%;
    max-width: 50rem;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
  }
}
