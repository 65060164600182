.moderatorPage {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  .flaggedLinksPanel {
    display: flex;
    flex-direction: column;
    .heading {
      margin: 0.25rem;
      font-size: larger;
    }
    .linkDiv {
      margin: 0.5rem 0;
      display: flex;
      padding: 0.5rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
    }
  }
  .detailedLink {
    .keyValue {
      margin: 0.25rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      .key {
        font-size: 0.85rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      .value {
        font-size: 1.25rem;
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .buttonGroup {
      display: flex;
      align-self: flex-end;
      flex-wrap: wrap;
      margin: 1rem 0;
    }
  }
}
