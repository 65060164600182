.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  .body {
    flex: 1 1 auto;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #1a202c;
    color: #edf2f7;
    ::-webkit-scrollbar {
      width: 0.5rem;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 0.25rem;
      background-color: #4a5568;
    }
  }
}
