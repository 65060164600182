.profilePage {
  --transition-time: 250ms;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: clip;
  padding: 0.5rem;
  box-sizing: border-box;
  .photoAndName {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 1rem 0;
    .photo {
      width: 4rem;
      height: 4rem;
      flex-shrink: 1;
      margin: 0.25rem;
      border-radius: 50%;
    }
    .nameAndBadge {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin: 0 1rem;
      .name {
        font-size: larger;
      }
      .moderatorBadge {
        background-color: #2b6cb0;
        font-size: 0.8rem;
        color: white;
        padding: 0.1rem 0.5rem;
        border-radius: 0.6rem;
        flex: 0 1 auto;
        width: fit-content;
      }
      .linksCount {
        margin: 0.5rem;
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 350;
      }
    }
  }
  .linksList {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    .linkDiv {
      margin: 0.5rem 0;
      display: flex;
      padding: 0.5rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
    }
    .linkDetails {
      display: flex;
      flex-direction: column;
      max-height: 25rem;
      transition: max-height ease-in-out var(--transition-time);
      overflow: clip;
      .buttonGroup {
        align-self: flex-end;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .hiddenLink {
      max-height: 0;
    }
  }
  .shareButton {
    align-self: flex-end;
    margin: 0.25rem;
  }
  .doesntExist {
    margin: auto 0;
  }
}
