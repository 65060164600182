.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  .filterContainer {
    padding: 1rem 0;
    background-color: #1a202c;
    .filterInput {
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      border: 1px solid #a0aec0;
      border-radius: 2.5rem;
      .filterIcon {
        margin: 0.1rem;
      }
      .filterTextBox {
        margin: 0.1rem 0.5rem;
        border: none;
        background-color: #00000000;
        color: #edf2f7;
      }
    }
  }
  .courseList {
    flex: 0 1 auto;
    overflow-y: auto;
    .coursePanel {
      display: flex;
      max-width: 100%;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #a0aec0;
      border-bottom: 1px solid #a0aec0;
      padding: 0.25rem;
      cursor: pointer;
      .courseTexts {
        display: flex;
        flex-direction: column;
        .courseTitle,
        .courseCode {
          font-weight: 350;
          word-wrap: normal;
          margin: 0.25rem 0;
          text-transform: uppercase;
        }
        .courseCode {
          font-size: 1.25rem;
        }
      }
    }
  }
}
