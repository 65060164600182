.action {
  display: flex;
  align-items: center;
  .icon {
    margin: 0.2rem;
  }
  .text {
    padding: 0.2rem;
  }
}
