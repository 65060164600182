.linkDiv {
  width: 100%;
  .keyValue {
    margin: 0.75rem 0;
    display: flex;
    flex-direction: column;
    .key {
      text-transform: uppercase;
      font-size: 0.75rem;
      margin: 0.25rem 0.5rem;
    }
    .value {
      padding: 0.1rem 0.75rem;
      border: 3px solid #e5e5e5;
      border-radius: 1.25rem;
    }
  }
}
