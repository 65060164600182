.navbar {
  --transition-time: 250ms;
  --size: 1rem;
  background-color: #1a202c;
  color: #edf2f7;
  .homeButton {
    color: #edf2f7;
  }
  .navItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .signInButton {
      margin: calc(0.5 * var(--size));
    }
    .userContainer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      .userDisplay {
        min-height: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        .userName {
          padding: calc(0.5 * var(--size));
        }
        .userImage {
          height: calc(2 * var(--size));
          border-radius: 50%;
        }
        .dropdownButton {
          height: 0;
          width: 0;
          margin: calc(0.5 * var(--size));
          border-top: calc(0.8 * var(--size)) solid #edf2f7;
          border-left: calc(0.5 * var(--size)) solid transparent;
          border-right: calc(0.5 * var(--size)) solid transparent;
        }
      }
      .dropdown {
        background-color: #1a202c;
        color: #edf2f7;
      }
    }
  }
}
